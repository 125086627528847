import { useState, useReducer, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import produce from 'immer'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Table from 'react-bootstrap/Table'
import { createSelector } from '@reduxjs/toolkit'

import { updateCorporation } from '../index'
import { TextInput, UnlockButton } from '../../TextInput'

const SelectAllCorporations = createSelector((state) => state.corporations, (corporations) => corporations);

const merge = (target, source) => {
    for (const key of Object.keys(source))
        if (source[key] instanceof Object) Object.assign(source[key], merge(target[key], source[key]))
    Object.assign(target || {}, source)
    return target
}

function updateCorporationReducer(draft, field, value) {
    const path = field.split(".")
    const last_item = path.pop()
    let path_to_object = draft
    for (let i in path)
        path_to_object = path_to_object[path[i]]
    path_to_object[last_item] = value
}

export default function Layout(props) {
    const dispatch = useDispatch();
    const corporations = useSelector(SelectAllCorporations);
    const corporation = corporations[props.id] || {}
    const reducer = (draft, { field, value, type }) => {
        switch (type) {
            case "update":
                return updateCorporationReducer(draft, field, value)
            default:
                console.error("Unknown type: " + type)
                return draft;
        }
    };

    const [timer, setTimer] = useState(null);
    const [isChanged, setIsChanged] = useState(false);
    const [state, setState] = useReducer(produce(reducer), corporation);

    useEffect(() => {
        if (!isChanged)
            return

        clearTimeout(timer)
        setTimer(setTimeout(() => {
            dispatch(updateCorporation(state))
        }, 2000))
        setIsChanged(false)
    }, [isChanged, setIsChanged, timer, setTimer, state, dispatch]);

    const onChange = (type) => {
        return (e) => {
            setIsChanged(true);
            setState({ field: e.target.name, value: e.target.value, type: type });
        }
    }

    return (
        <>
            <UnlockButton {...props} />
            <Row>
                {/* <TextInput name="id" state={state} onChange={onChange} {...props} readOnly /> */}
                <TextInput name="corporation_name" state={state} onChange={onChange} {...props} />
            </Row>
            <Row>
                <TextInput name="street" col="3" state={state} onChange={onChange} {...props} />
                <TextInput name="house_number" col="1" state={state} onChange={onChange} {...props} />
            </Row>
            <Row>
                <TextInput name="postal_code" col="1" state={state} onChange={onChange} {...props} />
                <TextInput name="city" col="3" state={state} onChange={onChange} {...props} />
            </Row>
            <Row>
                <TextInput name="broker_number" state={state} onChange={onChange} {...props} />
            </Row>
            Ansprechpartner
            <Table className="table" >
                <thead>
                    <tr>
                        <th scope="col">Bezeichnung</th>
                        <th scope="col">Telefon</th>
                        <th scope="col">Fax</th>
                        <th scope="col">Mail</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(corporation.point_of_contacts || {}).map(([, value]) => {
                        return (
                            <tr>
                                <td>{value.description}</td>
                                <td>{value.phone_number}</td>
                                <td>{value.fax_number}</td>
                                <td>{value.email}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
            Courtage
            <Table className="table" >
                <thead>
                    <tr>
                        <th scope="col">Bezeichnung</th>
                        <th scope="col">AP</th>
                        <th scope="col">BP</th>
                        <th scope="col">Maximierung</th>
                        <th scope="col">Stornoreserve</th>
                        <th scope="col">Faktor</th>
                        <th scope="col">Haftungszeit</th>
                        <th scope="col">Bemerkung</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(corporation.courtage || {}).map(([, value]) => {
                        return (
                            <tr>
                                <td>{value.description}</td>
                                <td>{value.ap}%</td>
                                <td>{value.bp}%</td>
                                <td>{value.maximization}</td>
                                <td>{value.cancellation_reserve}%</td>
                                <td>{value.factor}</td>
                                <td>{value.liability_period}</td>
                                <td>{value.note}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        </>
    )
}
