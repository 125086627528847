import Row from 'react-bootstrap/Row';

import { TextInput, DatePickerInput } from '../../TextInput'

export default function Layout(props) {
    return (
        <>
            <Row>
                <TextInput name="id_card_number" col="1" {...props} />
                <DatePickerInput name="date_of_issue" col="1" {...props} />
                <DatePickerInput name="expiration_date" col="1" {...props} />
                <TextInput name="issuing_authority" col="2" {...props} />
            </Row>
        </>
    )
}