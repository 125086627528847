import { useState, useReducer, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import produce from 'immer'
import Row from 'react-bootstrap/Row';
import { createSelector } from '@reduxjs/toolkit'
import { updateMaterialrisk } from '../index'
import { TextInput, DropdownInput, UnlockButton } from '../../TextInput'

import Bicycle from './bicycle'
import Car from './car'
import Condominium from './condominium'
import Pet from './pet'
import Tenement from './tenement'
import Valueable from './valueable'

const SelectAllMaterialrisks = createSelector((state) => state.materialrisks, (materialrisks) => materialrisks);

const merge = (target, source) => {
    for (const key of Object.keys(source))
        if (source[key] instanceof Object) Object.assign(source[key], merge(target[key], source[key]))
    Object.assign(target || {}, source)
    return target
}

function updateMaterialriskReducer(draft, field, value) {
    const path = field.split(".")
    const last_item = path.pop()
    let path_to_object = draft
    for (let i in path)
        path_to_object = path_to_object[path[i]]
    path_to_object[last_item] = value
}

export default function Layout(props) {
    const dispatch = useDispatch();
    const materialrisks = useSelector(SelectAllMaterialrisks);
    const materialrisk = materialrisks[props.id]
    const reducer = (draft, { field, value, type }) => {
        switch (type) {
            case "update":
                return updateMaterialriskReducer(draft, field, value)
            default:
                console.error("Unknown type: " + type)
                return draft;
        }
    };

    const [timer, setTimer] = useState(null);
    const [isChanged, setIsChanged] = useState(false);
    const [state, setState] = useReducer(produce(reducer), materialrisk);

    useEffect(() => {
        if (!isChanged)
            return

        clearTimeout(timer)
        setTimer(setTimeout(() => {
            dispatch(updateMaterialrisk(state))
        }, 2000))
        setIsChanged(false)
    }, [isChanged, setIsChanged, timer, setTimer, state, dispatch]);

    const onChange = (type) => {
        return (e) => {
            setIsChanged(true);
            setState({ field: e.target.name, value: e.target.value, type: type });
        }
    }

    return (
        <>
            <UnlockButton {...props} />
            <Row>
                <TextInput name="description" col="1" state={state} onChange={onChange} {...props} />
                <DropdownInput name="type" col="2" options={["tenement", "condominium", "bicycle", "car", "pet", "valueable"]} state={state} onChange={onChange} {...props} />
                <TextInput name="status" col="1" state={state} onChange={onChange} {...props} />
            </Row>
            {(() => {
                switch (state.type) {
                    case "tenement": return <Tenement state={state} onChange={onChange} {...props} />
                    case "condominium": return <Condominium state={state} onChange={onChange} {...props} />
                    case "bicycle": return <Bicycle state={state} onChange={onChange} {...props} />
                    case "car": return <Car state={state} onChange={onChange} {...props} />
                    case "pet": return <Pet state={state} onChange={onChange} {...props} />
                    case "valueable": return <Valueable state={state} onChange={onChange} {...props} />
                    default: return null;
                }
            })()}
        </>
    )
}
