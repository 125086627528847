import Row from 'react-bootstrap/Row';

import { TextInput, CheckboxInput } from '../../TextInput'

export default function Layout(props) {
    return (
        <>
            <Row>
                <TextInput name="license_plate" col="2" {...props} />
                <TextInput name="hsn" col="1" {...props} />
                <TextInput name="tsn" col="1" {...props} />
                <TextInput name="milage" col="1" {...props} />
                <TextInput name="milage_date" col="1" {...props} />
            </Row>
            <Row>
                <TextInput name="initial_registration_date" col="1" {...props} />
                <TextInput name="registration_date" col="1" {...props} />
                <CheckboxInput name="is_first_owner" col="1" {...props} />
                <CheckboxInput name="immobilizer" col="1" {...props} />
                <TextInput name="price" col="1" {...props} />
                <TextInput name="yearly_mileage" col="1" {...props} />
                <TextInput name="night_parking" col="1" {...props} />
            </Row>
            <Row>
                <CheckboxInput name="lane_assist" col="1" {...props} />
                <CheckboxInput name="turn_assist" col="1" {...props} />
                <CheckboxInput name="adaptive_cruise_control" col="1" {...props} />
                <CheckboxInput name="emergency_brake_assist" col="1" {...props} />
                <CheckboxInput name="financing" col="1" {...props} />
                <CheckboxInput name="vehicle_usage" col="1" {...props} />
                <CheckboxInput name="betrag" col="1" {...props} />
            </Row>
        </>
    )
}