import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const api = createApi({
    reducerPath: 'api',
    tagTypes: ['auth', 'user', 'customers'],
    baseQuery: fetchBaseQuery({
        baseUrl: 'http://localhost:3000/api/',
        prepareHeaders: (headers, { getState }) => {
            const token = getState().user.access_token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }

            return headers;
        }
    }),
    endpoints: (builder) => ({
        getUserSelf: builder.query({
            query: () => `user/self`,
            providesTags: (result, error) => [{ type: 'user' }],
        }),
        signIn: builder.mutation({
            query: (payload) => ({
                url: `user/signin`,
                method: 'POST',
                body: payload,
            }),
        }),
        getRefreshToken: builder.query({
            query: () => `user/refresh_token`,
        }),
        getCustomers: builder.query({
            query: () => `customers`,
            providesTags: (result, error) => [{ type: 'customers' }],
        }),
        getCustomer: builder.query({
            query: (id) => `customers/${id}`,
            providesTags: (result, error) => [{ type: 'customers' }],
        }),
    }),
});

export const { useGetUserSelfQuery, useGetRefreshTokenQuery, useSignInMutation, useGetCustomersQuery, useGetCustomerQuery } = api;
