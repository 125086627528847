import { useSelector, useDispatch } from 'react-redux'
import { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row';

import { SelectContractsForCustomer } from '../contracts'
import { SelectDocumentsForCustomer } from '../documents'
import { TextInput } from '../TextInput'
import { updateCustomer } from '../customers'
import { useOpenTab, close_current_tab } from '../tabs'
import ContractsOverview from '../contracts/overview'
import DocumentsOverview from '../documents/overview'

export default function Layout(props) {
    const tab = useOpenTab()
    const dispatch = useDispatch()
    const [state, setState] = useState(props.customer)

    function handleSubmit(e) {
        e.preventDefault()
        dispatch(updateCustomer({
            "id": state.id,
            "street": state.street,
            "house_number": state.house_number,
            "postal_code": state.postal_code,
            "city": state.city,
        }))
        dispatch(close_current_tab())
    }

    function handleChange(action) {
        return (event) => {
            const new_state = { ...state }
            new_state[event.target.name] = event.target.value
            setState(new_state)
        }
    }


    const documents = useSelector(SelectDocumentsForCustomer(state.id));
    const contracts = useSelector(SelectContractsForCustomer(state.id));

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Form.Group>
                    <Row>
                        Umzug für <Button variant="link" onClick={tab({ "data": { "id": 0 } })} style={{ margin: 0, padding: 0 }}>{props.customer.last_name} {props.customer.first_name}</Button>
                    </Row>
                    <Row>
                        <TextInput name="street" col="2" {...props} state={state} onChange={handleChange} />
                        <TextInput name="house_number" col="1" {...props} state={state} onChange={handleChange} />
                    </Row>
                    <Row>
                        <TextInput name="postal_code" col="1" {...props} state={state} onChange={handleChange} />
                        <TextInput name="city" col="1" {...props} state={state} onChange={handleChange} />
                    </Row>
                    <br />
                    Folgende Gesellschaften über die Namensänderung informieren:
                    <ContractsOverview data={contracts} {...props} checkbox={true} />
                    Folgende Dokumente als Anhand anfügen:
                    <DocumentsOverview data={documents} {...props} checkbox={true} />
                    <Row>
                        <Button name="submit" type="submit" col="2" {...props} >Änderung durchführen</Button>
                    </Row>
                </Form.Group>
            </Form>
        </>
    )
}