import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'

import { useState, useReducer, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import produce from 'immer'
import { createSelector } from '@reduxjs/toolkit'
import { updateContract } from '../index'
import { SelectAllCorporations } from '../../corporations'
import { TextInput, UnlockButton, TypeaheadInput, DatePickerInput } from '../../TextInput'
import { useOpenTab } from '../../tabs'

const SelectAllContracts = createSelector((state) => state.contracts, (contracts) => contracts);

const merge = (target, source) => {
    for (const key of Object.keys(source))
        if (source[key] instanceof Object) Object.assign(source[key], merge(target[key], source[key]))
    Object.assign(target || {}, source)
    return target
}

function updateContractReducer(draft, field, value) {
    const path = field.split(".")
    const last_item = path.pop()
    let path_to_object = draft
    for (let i in path)
        path_to_object = path_to_object[path[i]]
    path_to_object[last_item] = value
}

export default function Layout(props) {
    const dispatch = useDispatch();
    const contracts = useSelector(SelectAllContracts);
    const corporations = useSelector(SelectAllCorporations);
    const contract = contracts[props.id]
    const reducer = (draft, { field, value, type }) => {
        switch (type) {
            case "update":
                return updateContractReducer(draft, field, value)
            default:
                console.error("Unknown type: " + type)
                return draft;
        }
    };

    const [timer, setTimer] = useState(null);
    const [isChanged, setIsChanged] = useState(false);
    const [state, setState] = useReducer(produce(reducer), contract);

    useEffect(() => {
        if (!isChanged)
            return

        clearTimeout(timer)
        setTimer(setTimeout(() => {
            dispatch(updateContract(state))
        }, 2000))
        setIsChanged(false)
    }, [isChanged, setIsChanged, timer, setTimer, state, dispatch]);

    const onChange = (type) => {
        return (e) => {
            // console.log(e)
            setIsChanged(true);
            setState({ field: e.target.name, value: e.target.value, type: type });
        }
    }
    const tab = useOpenTab()

    return (
        <>
            <UnlockButton {...props} />
            <Row>
                <TextInput name="contract_number" state={state} onChange={onChange} col="1" {...props} />
                <TypeaheadInput name="corporation.corporation_name" translation="corporation" state={state} onChange={onChange} col="2" options={Object.values(corporations)} labelKey="corporation_name" selected={state.corporation} component="Corporations" {...props} />
                <TextInput name="broker_number" state={state} onChange={onChange} col="1" {...props} />
                <TextInput name="status" state={state} onChange={onChange} col="1" {...props} />
            </Row>
            <Row>
                <TextInput name="branch" state={state} onChange={onChange} col="1" {...props} />
                <TextInput name="product" state={state} onChange={onChange} col="1" {...props} />
                <TextInput name="rate" state={state} onChange={onChange} col="1" {...props} />
                <TextInput name="premium" state={state} onChange={onChange} col="1"{...props} />
            </Row>
            <Row>
                <DatePickerInput name="application_date" state={state} onChange={onChange} col="1" {...props} />
                <DatePickerInput name="policy_date" state={state} onChange={onChange} col="1" {...props} />
                <DatePickerInput name="start_date" state={state} onChange={onChange} col="1" {...props} />
                <DatePickerInput name="expiration_date" state={state} onChange={onChange} col="1" {...props} />
            </Row>
            <Row>
                <TypeaheadInput name="customer.name" translation="insurance_holder" state={state} onChange={onChange} col="2" options={state.customer} labelKey="name" selected={state.customer} disabled component="Customers" {...props} />
                <TextInput name="insured_person" state={state} onChange={onChange} col="2" {...props} />
            </Row>
        </>
    )
}
