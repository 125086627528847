import { createSelector, createSlice } from '@reduxjs/toolkit'
import { useSelector, useDispatch } from 'react-redux'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'

const counterSlice = createSlice({
    name: 'counter',
    initialState: 0,
    reducers: {
        increment: state => state + 1,
        decrement: state => state - 1,
        reset: state => 0,
        incrementAsync: state => state + 1,
    }
})

export const SelectCount = createSelector((state) => state.counter, (counter) => counter);

export default function Layout() {
    const dispatch = useDispatch();
    const selectCounterValue = useSelector(SelectCount);
    return (
        <Container fluid >
            <p>{selectCounterValue}</p>
            <Button id="increment" onClick={() => dispatch(increment())}>+</Button>
            <Button id="decrement" onClick={() => dispatch(decrement())}>-</Button>
            <Button id="reset" onClick={() => dispatch(reset())}>reset</Button>
        </Container>
    )
}

export const countSelector = (state) => state.counter;
export const { actions, reducer } = counterSlice
export const { increment, decrement, reset, incrementAsync } = actions