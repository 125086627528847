import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query/react'

import { reducer as ContractsReducer } from './components/contracts'
import { reducer as CorporationsReducer } from './components/corporations'
import { reducer as CounterReducer } from './components/counter'
import { reducer as CustomersReducer } from './components/customers'
import { reducer as DocumentsReducer } from './components/documents'
import { reducer as MaterialRisksReducer } from './components/materialrisks'
import { reducer as TimelineReducer } from './components/timeline'
import UserReducer from './components/authentication/slice'
import { reducer as TabsReducer } from './components/tabs'
import { reducer as EmployeesReducer } from './components/employees'
import { api } from './server_api.js'

const rootReducer = combineReducers({
    contracts: ContractsReducer,
    corporations: CorporationsReducer,
    counter: CounterReducer,
    customers: CustomersReducer,
    documents: DocumentsReducer,
    materialrisks: MaterialRisksReducer,
    timeline: TimelineReducer,
    user: UserReducer,
    tabs: TabsReducer,
    employees: EmployeesReducer,
    [api.reducerPath]: api.reducer,
});

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(api.middleware),
    devTools: true,
});

setupListeners(store.dispatch)

export default store;