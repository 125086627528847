import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row';
import { TextInput, CheckboxInput, DropdownInput, DatePickerInput } from '../../TextInput'

export default function Layout(props) {
    return (
        <>
            <Row>
                <TextInput name="id" {...props} readOnly />
                <DropdownInput name="position" {...props} options={["trainee", "consultant", "senior_consultant", "teamlead", "assistant"]} />
            </Row>
            <Row>
                <DropdownInput name="salutation" col="1" options={["salutation_sir", "salutation_madam", "salutation_diverse", "salutation_company"]} {...props} />
                <TextInput name="titel" col="1" {...props} />
            </Row>
            <Row>
                <TextInput name="last_name" {...props} />
                <TextInput name="first_name" {...props} />
                <Col xl="1" ></Col>
                <DatePickerInput name="birth_date" {...props} col="1" />
                <TextInput name="birth_place" {...props} />
            </Row>
            <Row>
                <TextInput name="street" {...props} col="3" />
                <TextInput name="house_number" {...props} col="1" />
                <Col xl="1" ></Col>
                <DropdownInput name="martial_status" col="1" options={["martial_status_single", "martial_status_married", "martial_status_divorced", "martial_status_widowed"]} {...props} />
                <TextInput name="nationality" {...props} />
            </Row>
            <Row>
                <TextInput name="postal_code" {...props} col="1" />
                <TextInput name="city" {...props} />
            </Row>
            <Row>
                <TextInput name="state" {...props} />
                <TextInput name="country" {...props} />
            </Row>
            <Row>
                <TextInput name="mobile_number" {...props} />
                <TextInput name="phone_number" {...props} />
            </Row>
            <Row>
                <TextInput name="fax_number" {...props} />
                <TextInput name="email" {...props} col="3" />
            </Row>
        </>
    )
}
