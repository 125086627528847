import { useState, useReducer, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import produce from 'immer'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row';
import { createSelector } from '@reduxjs/toolkit'
import { updateDocument } from '../index'
import { TextInput, DropdownInput, UnlockButton, DatePickerInput } from '../../TextInput'
import IDCard from './id_card'

const SelectAllDocuments = createSelector((state) => state.documents, (documents) => documents);

const merge = (target, source) => {
    for (const key of Object.keys(source))
        if (source[key] instanceof Object) Object.assign(source[key], merge(target[key], source[key]))
    Object.assign(target || {}, source)
    return target
}

function updateDocumentReducer(draft, field, value) {
    const path = field.split(".")
    const last_item = path.pop()
    let path_to_object = draft
    for (let i in path)
        path_to_object = path_to_object[path[i]]
    path_to_object[last_item] = value
}

export default function Layout(props) {
    const dispatch = useDispatch();
    const documents = useSelector(SelectAllDocuments);
    const document = documents[props.id]
    const reducer = (draft, { field, value, type }) => {
        switch (type) {
            case "addRealEstate":
                draft.real_estate = draft.real_estate || {}
                draft.real_estate[Math.max(...Object.keys(draft.real_estate || {}).map(Number), 0) + 1] = {}
                break
            case "removeRealEstate":
                delete draft.real_estate[value];
                break
            case "addVehicle":
                draft.vehicles = draft.vehicles || {}
                draft.vehicles[Math.max(...Object.keys(draft.vehicles).map(Number), 0) + 1] = {}
                break
            case "removeVehicle":
                delete draft.vehicles[value];
                break
            case "update":
                return updateDocumentReducer(draft, field, value)
            default:
                console.error("Unknown type: " + type)
                return draft;
        }
    };

    const [timer, setTimer] = useState(null);
    const [isChanged, setIsChanged] = useState(false);
    const [state, setState] = useReducer(produce(reducer), document);
    const [isLocked, setIsLocked] = useState(true);

    useEffect(() => {
        if (!isChanged)
            return

        clearTimeout(timer)
        setTimer(setTimeout(() => {
            dispatch(updateDocument(state))
        }, 2000))
        setIsChanged(false)
    }, [isChanged, setIsChanged, timer, setTimer, state, dispatch]);

    const onChange = (type) => {
        return (e) => {
            setIsChanged(true);
            setState({ field: e.target.name, value: e.target.value, type: type });
        }
    }

    return (
        <>
            <UnlockButton {...props} />
            <Row>
                <TextInput name="file_name" col="1" state={state} onChange={onChange} {...props} />
                <DropdownInput name="type" col="2" options={["id_card", "passport", "policy", "offer", "correspondence", "application", "brokerage_agreement", "power_of_attorney", "power_of_attorney_for_information", "consulting_documentation", "privacy_policy", "other"]} state={state} onChange={onChange} {...props} />
                <TextInput name="relation.name" col="1" state={state} onChange={onChange} {...props} />
                <DatePickerInput name="adding_date" col="1" state={state} onChange={onChange} {...props} />
                <TextInput name="status" col="1" state={state} onChange={onChange} {...props} />
                <TextInput name="note" col="1" state={state} onChange={onChange} {...props} />
            </Row>
            {(() => {
                switch (state.type) {
                    case "id_card": return <IDCard state={state} onChange={onChange} {...props} />
                    case "passport": return <IDCard state={state} onChange={onChange} {...props} />
                    default: return null;
                }
            })()}
            Vorschau
        </>
    )
}
