import { createSelector, createSlice } from '@reduxjs/toolkit'
import { RiArrowDropRightFill } from 'react-icons/ri';
import { useSelector, useDispatch } from 'react-redux'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Table from 'react-bootstrap/Table'

import CorporationDetails from './details'
import { AiFillCalculator } from 'react-icons/ai';
import { useOpenTab } from '../tabs'

const corporationSlice = createSlice({
    name: 'corporations',
    initialState: {},
    reducers: {
        addEventToHistory: (state, data) => state.find(corporation => corporation.id === data.payload.id).history.append({ ts: new Date().toISOString(), text: data.text }),
        removeCorporation: (state, data) => state.filter(corporation => (corporation.firstname !== data.payload.firstname) && (corporation.lastname !== data.payload.lastname)),
        updateCorporation: (state, data) => {
            const corporation = state[data.payload.id]
            if (!("history" in corporation))
                corporation.history = []

            for (var prop in data.payload)
                if (corporation[prop] !== data.payload[prop]) {
                    // corporation.history.append({ ts: new Date().toISOString(), text: `updated ${Translation[prop]} from ${corporation[prop]} to ${data.payload[prop]}` })
                    corporation[prop] = data.payload[prop]
                }
        },
        addCorporation: (state, data) => {
            state[data.payload] = { "id": data.payload }
        }
    }
})

export const SelectAllCorporations = createSelector((state) => state.corporations, (corporations) => corporations);

export const { actions, reducer } = corporationSlice
export const { removeCorporation, updateCorporation, addCorporation } = actions

export function useAddCorporationOnClick() {
    const dispatch = useDispatch();
    const tab = useOpenTab()
    const customers = useSelector(SelectAllCorporations);
    return (e) => {
        e.preventDefault()
        const id = Math.max(...Object.keys(customers || {}).map(Number), 0) + 1
        dispatch(addCorporation(id))
        tab({ "component": "Corporations", "data": { "id": id, "locked": false } })()
    }
}

export default function Layout(props) {
    const corporations = useSelector(SelectAllCorporations);
    const tab = useOpenTab()

    return (
        <Container fluid >
            {(() => {
                if (props && props.hasOwnProperty("id"))
                    return (
                        <CorporationDetails {...props} />
                    )
                else
                    return (
                        <Table class="table" >
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Vermittlernummer</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(corporations).map(([, value]) => {
                                    return (
                                        <tr>
                                            <td><Button variant="link" onClick={tab({ "component": "Corporations", "data": { "id": value.id } })} key={value.id}><RiArrowDropRightFill /> {value.corporation_name}</Button></td>
                                            <td><Button variant="link" onClick={tab({ "component": "Corporations", "data": { "id": value.id } })} key={value.id}>{value.broker_number}</Button></td>
                                            <td><AiFillCalculator size={25} /></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    )
            })()}
        </Container>
    )
}
