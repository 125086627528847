import Row from 'react-bootstrap/Row';
import { TextInput } from '../../TextInput'

export default function Layout(props) {
    return (
        <>
            <Row>
                <TextInput name="name" col="2" {...props} />
                <TextInput name="type" col="2" {...props} />
                <TextInput name="birth_date" col="1" {...props} />
                <TextInput name="chip_id" col="2" {...props} />
            </Row>
        </>
    )
}