import { createSelector, createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Table from 'react-bootstrap/Table'
import axios from 'axios'


import { useGetCustomersQuery } from 'server_api.js'
import CustomerDetails from './customer_details'
import { RiArrowDropRightFill, RiContactsBookLine } from 'react-icons/ri';
import { useOpenTab } from '../tabs'
import { useAddDocumentOnClick } from '../documents'
import { useAddMaterialriskOnClick } from '../materialrisks'
import { useAddContractOnClick } from '../contracts'

const customerSlice = createSlice({
    name: "customers",
    initialState: {
    },
    reducers: {
        // addEventToHistory: (state, data) => state.find(customer => customer.id === data.payload.id).history.append({ ts: new Date().toISOString(), text: data.text }),
        // removeCustomer: (state, data) => state.filter(customer => (customer.firstname !== data.payload.firstname) && (customer.lastname !== data.payload.lastname)),
        // updateCustomer: (state, data) => {
        //     const customer = state[data.payload.id];
        //     if (!("history" in customer))
        //         customer.history = []

        //     for (var prop in data.payload)
        //         if (customer[prop] !== data.payload[prop]) {
        //             // customer.history.append({ ts: new Date().toISOString(), text: `updated ${Translation[prop]} from ${customer[prop]} to ${data.payload[prop]}` })
        //             customer[prop] = data.payload[prop]
        //         }
        // },
        // addCustomer: (state, data) => {
        //     state[data.payload] = { "id": data.payload }
        // },
    },
})


export const { actions, reducer } = customerSlice
export const { removeCustomer, updateCustomer, addCustomer, setStatus } = actions

// export function useAddCustomerOnClick() {
//     const dispatch = useDispatch()
//     const tab = useOpenTab()

//     return () => {
//         const id = Math.max(...Object.keys(customers || {}).map(Number), 0) + 1
//         dispatch(addCustomer(id))
//         tab({ "component": "Customers", "data": { "id": id, "locked": false } })()
//     }
// }

export default function Layout(props) {
    const { data = {}, error, loading } = useGetCustomersQuery()
    const tab = useOpenTab()

    return (
        <Container fluid>
            {(() => {
                if (props && props.hasOwnProperty("id"))
                    return (
                        <CustomerDetails {...props} />
                    )
                else
                    return (
                        <Table className="table" >
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Vorname</th>
                                    <th scope="col">Geburtsdatum</th>
                                    <th scope="col">Ort</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(data).map(([key, customer]) => {
                                    return (
                                        <tr key={customer.id}>
                                            <td><Button variant="link" onClick={tab({ "data": { "id": customer.id } })}><RiArrowDropRightFill /> {customer.last_name}</Button></td>
                                            <td><Button variant="link" onClick={tab({ "data": { "id": customer.id } })}>{customer.first_name}</Button></td>
                                            <td><Button variant="link" onClick={tab({ "data": { "id": customer.id } })}>{new Date(customer.birth_date).toLocaleDateString("de")}</Button></td>
                                            <td><Button variant="link" onClick={tab({ "data": { "id": customer.id } })}>{customer.city}</Button></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    )
            })()}
        </Container >
    )
}
