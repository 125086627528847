import { useSelector, useDispatch } from 'react-redux'
import { createSelector, createSlice } from '@reduxjs/toolkit'
// import Timeline from 'react-time-line'

const timelineSlice = createSlice({
    name: 'timeline',
    initialState: [
        { ts: "2017-09-17T12:22:46.587Z", text: 'Logged in' },
        { ts: "2017-09-17T12:21:46.587Z", text: 'Clicked Home Page' },
        { ts: "2017-09-17T12:20:46.587Z", text: 'Edited Profile' },
        { ts: "2017-09-16T12:22:46.587Z", text: 'Registred' },
        { ts: "2017-09-16T12:21:46.587Z", text: 'Clicked Cart' },
        { ts: "2017-09-16T12:20:46.587Z", text: 'Clicked Checkout' },
    ],
    reducers: {
        addEvent: (state, data) => state.append({ ts: new Date().toISOString(), text: data.text }),
        replaceAllEvents: (state, data) => state = data,
    }
})

const SelectAllEvents = createSelector((state) => state.timeline, (timeline) => timeline);

export const { actions, reducer } = timelineSlice
export const { addEvent, replaceAllEvents } = actions

export default function Layout() {
    const dispatch = useDispatch();
    const events = useSelector(SelectAllEvents);

    return (
        <>
            {/* <Timeline items={events} /> */}
        </>
    )
}
