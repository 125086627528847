import { RiArrowDropRightFill } from 'react-icons/ri'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'

import { translate } from '../../translation'
import { useOpenTab } from '../tabs'

export default function Layout(props) {
    const tab = useOpenTab()
    return (
        <Table className="table" >
            <thead>
                <tr>
                    <th scope="col">Bezeichnung</th>
                    <th scope="col">Art</th>
                    <th scope="col">Datum</th>
                    <th scope="col">Status</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {Object.entries(props.data || {}).map(([key, value]) => {
                    return (
                        <tr>
                            <td><Button variant="link" onClick={tab({ "component": "Materialrisks", "data": { "id": value.id } })}><RiArrowDropRightFill />{value.description}</Button></td>
                            <td><Button variant="link" onClick={tab({ "component": "Materialrisks", "data": { "id": value.id } })}>{translate(value.type)}</Button></td>
                            <td><Button variant="link" onClick={tab({ "component": "Materialrisks", "data": { "id": value.id } })}>{new Date(value.adding_date).toLocaleDateString("de")}</Button></td>
                            <td><Button variant="link" onClick={tab({ "component": "Materialrisks", "data": { "id": value.id } })}>{value.status}</Button></td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
    )
}