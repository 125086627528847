import { useSelector, useDispatch } from 'react-redux'
import Container from 'react-bootstrap/Container'
import { createSelector, createSlice } from '@reduxjs/toolkit'

import DocumentDetails from './details'
import Overview from './overview'
import { useOpenTab } from '../tabs'

const documentSlice = createSlice({
    name: 'documents',
    initialState: {},
    reducers: {
        addEventToHistory: (state, data) => state.find(document => document.id === data.payload.id).history.append({ ts: new Date().toISOString(), text: data.text }),
        removeDocument: (state, data) => state.filter(document => (document.id !== data.payload.id)),
        updateDocument: (state, data) => {
            const document = state[data.payload.id]
            if (!("history" in document))
                document.history = []

            for (var prop in data.payload)
                if (document[prop] !== data.payload[prop]) {
                    // document.history.append({ ts: new Date().toISOString(), text: `updated ${Translation[prop]} from ${document[prop]} to ${data.payload[prop]}` })
                    document[prop] = data.payload[prop]
                }
        },
        addDocument: (state, data) => {
            state[data.payload.id] = { ...data.payload }
        }
    }
})

export const SelectAllDocuments = createSelector((state) => state.documents, (documents) => documents);
export const SelectDocumentsForCustomer = (id) => createSelector((state) => state.documents, (documents) => {
    const result = {}
    Object.entries(documents).map(([key, value]) => {
        if (value?.customer?.[0]?.id === id)
            result[key] = value
    })
    return result
})

export const { actions, reducer } = documentSlice
export const { removeDocument, updateDocument, addDocument } = actions

export function useAddDocumentOnClick() {
    const dispatch = useDispatch();
    const documents = useSelector(SelectAllDocuments);
    const tab = useOpenTab()
    return (customer) => {
        return () => {
            const id = Math.max(...Object.keys(documents || {}).map(Number), 0) + 1
            dispatch(addDocument({ "id": id, "customer": [{ "id": customer.id, "name": customer.first_name + " " + customer.last_name }], "status": "Aktiv" }))
            tab({ "component": "Documents", "data": { "id": id, "locked": false } })()
        }
    }
}

export default function Layout(props) {
    const documents = useSelector(SelectAllDocuments);

    return (
        <Container fluid >
            {(() => {
                if (props && props.hasOwnProperty("id"))
                    return (
                        <DocumentDetails {...props} />
                    )
                else
                    return (
                        <Overview data={documents} />
                    )
            })()}
        </Container>
    )
}
