import { BsShuffle } from 'react-icons/bs'
import { createSelector } from '@reduxjs/toolkit'
import { useSelector, useDispatch } from 'react-redux'
import { useState, useReducer, useEffect } from 'react';
import Form from 'react-bootstrap/Form'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import produce from 'immer'

import { updateEmployee } from '../index'
import { useOpenTab } from '../../tabs'
import Personal from './Personal'
import { UnlockButton } from '../../TextInput';

const SelectAllEmployees = createSelector((state) => state.employees, (employees) => employees);

const merge = (target, source) => {
    for (const key of Object.keys(source))
        if (source[key] instanceof Object) Object.assign(source[key], merge(target[key], source[key]))
    Object.assign(target || {}, source)
    return target
}

function updateEmployeeReducer(draft, field, value) {
    const path = field.split(".")
    const last_item = path.pop()
    let path_to_object = draft
    for (let i in path)
        path_to_object = path_to_object[path[i]]
    path_to_object[last_item] = value
}

export default function Layout(props) {
    const dispatch = useDispatch();
    const employees = useSelector(SelectAllEmployees);
    // console.log(props.id)
    const employee = employees[props.id] || { id: 0 };
    const reducer = (draft, { field, value, type }) => {
        switch (type) {
            case "addRealEstate":
                draft.real_estate = draft.real_estate || {}
                draft.real_estate[Math.max(...Object.keys(draft.real_estate || {}).map(Number), 0) + 1] = {}
                break
            case "removeRealEstate":
                delete draft.real_estate[value];
                break
            case "addVehicle":
                draft.vehicles = draft.vehicles || {}
                draft.vehicles[Math.max(...Object.keys(draft.vehicles).map(Number), 0) + 1] = {}
                break
            case "removeVehicle":
                delete draft.vehicles[value];
                break
            case "update":
                return updateEmployeeReducer(draft, field, value)
            case "reinit":
                return value
            default:
                console.error("Unknown type: " + type)
                return draft;
        }
    };
    const [timer, setTimer] = useState(null);
    const [isChanged, setIsChanged] = useState(false);
    const [state, setState] = useReducer(produce(reducer), employee);
    if (state.id !== employee.id)
        setState({ value: employee, type: "reinit" })

    useEffect(() => {
        if (!isChanged)
            return

        clearTimeout(timer)
        setTimer(setTimeout(() => {
            dispatch(updateEmployee(state))
        }, 2000))
        setIsChanged(false)
    }, [isChanged, setIsChanged, timer, setTimer, state, dispatch]);

    const onChange = (type) => {
        return (e) => {
            setIsChanged(true);
            setState({ field: e.target.name, value: e.target.value, type: type });
        }
    }

    const tab = useOpenTab()

    return (
        <>
            <Nav variant="pills">
                <UnlockButton {...props} />
                <NavDropdown title={<BsShuffle />} id="nav-dropdown">
                </NavDropdown>
            </Nav>
            <Form id="mainform">
                <Personal state={state} onChange={onChange} {...props} />
            </Form>
        </>
    )
}
