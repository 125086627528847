import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import { useState } from 'react'
import { RiArrowDropRightFill } from 'react-icons/ri'

import { useOpenTab } from '../tabs'


export default function Layout(props) {
    const tab = useOpenTab()
    const [checkboxes, setCheckboxes] = useState({})

    function handleClick(e) {
        const name = e.target.name
        const checked = e.target.checked

        if (name === "checkall") {
            const state = { "checkall": checked }
            Object.entries(props.data || {}).map(([key, value]) => {
                state[value.id] = checked
            })
            setCheckboxes(state)
        } else {
            const state = { ...checkboxes }
            state[name] = checked
            if (!checked)
                state["checkall"] = false
            setCheckboxes(state)
        }
    }

    return (
        <Table className="table" >
            <thead>
                <tr>
                    {(() => {
                        if (props.checkbox)
                            return (<th><Form.Check checked={checkboxes[`checkall`]} onClick={handleClick} type="checkbox" name="checkall" onChange={props.onChange("update")} /></th>)
                    })()}
                    <th scope="col">Sparte</th>
                    <th scope="col">Produkt</th>
                    <th scope="col">Anbieter</th>
                    <th scope="col">Tarif</th>
                    <th scope="col">Vertrags-Nr.</th>
                    <th scope="col">Ablaufdatum</th>
                    <th scope="col">Leistung</th>
                    <th scope="col">Vertragswert</th>
                    <th scope="col">Prämie</th>
                    <th scope="col">Status</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                {Object.entries(props.data).map(([, value]) => {
                    return (
                        <tr>
                            {(() => {
                                if (props.checkbox)
                                    return (<td><Form.Check checked={checkboxes[value.id]} onClick={handleClick} type="checkbox" name={`contracts-checkbox-${value.id}`} onChange={props.onChange("update")} /></td>)
                            })()}
                            <td><Button variant="link" onClick={tab({ "component": "Contracts", "data": { "id": value.id } })}><RiArrowDropRightFill />{value.branch}</Button></td>
                            <td><Button variant="link" onClick={tab({ "component": "Contracts", "data": { "id": value.id } })}>{value.product}</Button></td>
                            <td><Button variant="link" onClick={tab({ "component": "Corporations", "data": { "id": value.corporation.id } })}>{value.corporation.name}</Button></td>
                            <td><Button variant="link" onClick={tab({ "component": "Contracts", "data": { "id": value.id } })}>{value.rate}</Button></td>
                            <td><Button variant="link" onClick={tab({ "component": "Contracts", "data": { "id": value.id } })}>{value.contract_number}</Button></td>
                            <td><Button variant="link" onClick={tab({ "component": "Contracts", "data": { "id": value.id } })}>{value.expiration_date ? new Date(value.expiration_date).toLocaleDateString("de") : ""}</Button></td>
                            <td><Button variant="link" onClick={tab({ "component": "Contracts", "data": { "id": value.id } })}>{value.benefit}€</Button></td>
                            <td><Button variant="link" onClick={tab({ "component": "Contracts", "data": { "id": value.id } })}>{value.contract_value}€</Button></td>
                            <td><Button variant="link" onClick={tab({ "component": "Contracts", "data": { "id": value.id } })}>{value.premium}€ {value.premium_interval}</Button></td>
                            <td><Button variant="link" onClick={tab({ "component": "Contracts", "data": { "id": value.id } })}>{value.status}</Button></td>
                            {/* <td><AiFillCalculator size={25} /></td> */}
                        </tr >
                    )
                })}
            </tbody >
        </Table >
    )
}
