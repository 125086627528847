import Button from 'react-bootstrap/Button'
import { useState, useRef, Fragment } from 'react';
import { useSelector } from 'react-redux'
import { BsPerson, BsFillExclamationTriangleFill, BsBuilding, BsCollection, BsFileEarmark, BsClipboard } from 'react-icons/bs'
import { useOpenTab } from '../tabs'

import { Typeahead } from 'react-bootstrap-typeahead'
import { SelectAllContracts } from '../contracts'
import { SelectAllDocuments } from '../documents'
import { SelectAllCorporations } from '../corporations';
import { SelectAllMaterialrisks } from '../materialrisks'

function getIcon(component) {
    switch (component) {
        case "Customers": return (<BsPerson />)
        case "Corporations": return (<BsBuilding />)
        case "Contracts": return (<BsCollection />)
        case "Documents": return (<BsFileEarmark />)
        case "Materialrisks": return (<BsClipboard />)
        default: return (<BsFillExclamationTriangleFill />)
    }
}

export default function Layout(props) {
    const [show, setShow] = useState(false);
    // const [target, setTarget] = useState(null);
    const allDocuments = useSelector(SelectAllDocuments)
    const target = useRef(null);

    const handleClick = (event) => {
        setShow(!show);
        // console.log(target)
        // setTarget(event.target);
    };

    // const customers = useSelector(SelectAllCustomers);
    const corporations = useSelector(SelectAllCorporations);
    const documents = useSelector(SelectAllDocuments);
    const contracts = useSelector(SelectAllContracts);
    const materialrisks = useSelector(SelectAllMaterialrisks);

    // console.log(contracts)

    // const data = Object.values(customers).map(customer => { return { ...customer, "component": "Customers", "name": customer.first_name + " " + customer.last_name } })
    const data = []
    data.push(...Object.values(corporations).map(corporation => { return { ...corporation, "component": "Corporations", "name": corporation.corporation_name } }))
    data.push(...Object.values(documents).map(document => { return { ...document, "component": "Documents", "name": document.file_name } }))
    data.push(...Object.values(contracts).map(contract => { return { ...contract, "component": "Contracts", "name": contract.customer[0].name + " " + contract.contract_number } }))
    data.push(...Object.values(materialrisks).map(materialrisk => { return { ...materialrisk, "component": "Materialrisks", "name": materialrisk.description } }))

    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState(data);

    // console.log(Object.values(customers))

    const filterByCallback = (option, props) => (
        option.capital.toLowerCase().indexOf(props.text.toLowerCase()) !== -1 ||
        option.name.toLowerCase().indexOf(props.text.toLowerCase()) !== -1
    );
    const tab = useOpenTab()

    const ref = useRef();

    return (
        <>
            <Typeahead
                clearButton={true}
                id="async-example"
                isLoading={isLoading}
                labelKey="name"
                // minLength={2}
                options={options}
                placeholder="Search..."
                size="lg"
                filterBy={['first_name', 'last_name']}
                ref={ref}
                // clearButton
                renderMenuItemChildren={(option, props) => (
                    <Fragment>
                        <Button variant="link" onClick={(e) => { e.stopPropagation(); tab({ "component": option.component, "data": { "id": option.id } })() }}>
                            <span>{getIcon(option.component)} {option.name}</span>
                        </Button>
                    </Fragment>
                )}
            />
        </>
    )
}