import Button from 'react-bootstrap/Button'
import { useState, useReducer, useEffect, useRef } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead'
import { BsLock, BsUnlock } from 'react-icons/bs'
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import DatePicker from "react-datepicker"
import { translate } from '../../translation'
import { useOpenTab } from '../tabs'

import "react-datepicker/dist/react-datepicker.css"



function OptionalColumn(props) {
    if (props.column)
        return (<Col>{props.children}</Col>)
    else
        return (<>{props.children}</>)
}


export default function TextInput(props) {
    const path = props.name.split(".")
    let value = props.state
    for (let i in path)
        if (!value || !value.hasOwnProperty(path[i])) {
            value = ""
            break
        }
        else
            value = value[path[i]]

    const field = value;
    return (
        <Form.Group as={props.column ? Row : Col} xl={props.col ? props.col : 2}>
            <Form.Label column={props.column}>{translate(props.translation ? props.translation : props.name)}</Form.Label>
            <OptionalColumn column={props.column}>
                {(() => {
                    if (props.locked)
                        return (
                            <>
                                <br />
                                {field}
                            </>
                        )
                    else
                        return (
                            <Form.Control name={props.name} value={field} key={props.key ? props.key : props.name} onChange={props.onChange("update")} readOnly={props.readOnly || props.locked} plaintext={props.locked} />
                        )
                })()}
            </OptionalColumn>
        </Form.Group>
    )
}

function CheckboxInput(props) {
    const field = props.state[props.name];
    return (
        <Form.Group as={props.column ? Row : Col} xl={props.col ? props.col : 2}>
            <Form.Label>{translate(props.translation ? props.translation : props.name)}</Form.Label>
            <Form.Check type="checkbox" name={props.name} defaultChecked={field} key={props.key ? props.key : props.name} onChange={props.onChange("update")} readOnly={props.readOnly} disabled={props.locked} />
        </Form.Group>
    )
}

function DropdownInput(props) {
    const field = props.state[props.name];
    return (
        <Form.Group as={props.column ? Row : Col} xl={props.col ? props.col : 2}>
            <Form.Label column={props.column}>{translate(props.translation ? props.translation : props.name)}</Form.Label>
            <OptionalColumn column={props.column}>
                {(() => {
                    if (props.locked)
                        return (
                            <>
                                <br />
                                {translate(field)}
                            </>
                        )
                    else
                        return (
                            <Form.Select name={props.name} onChange={props.onChange("update")} defaultValue={field} custom>
                                <option value=""></option>
                                {props.options.map((option, i) => (
                                    <option value={option} key={i} > {translate(option)}</option>
                                ))}
                            </Form.Select>
                        )
                })()}
            </OptionalColumn>
        </Form.Group >
    )
}

function UnlockButton(props) {
    const tab = useOpenTab()
    return (<Button variant="link" onClick={tab({ "data": { "locked": !props.locked } })} > {!props.locked ? <BsUnlock /> : <BsLock />}</Button >)
}

function DatePickerInput(props) {
    const path = props.name.split(".")
    let value = props.state
    for (let i in path)
        if (!value || !value.hasOwnProperty(path[i])) {
            value = ""
            break
        }
        else
            value = value[path[i]]

    const field = Date.parse(value);
    const onChangeFunction = props.onChange("update");
    function onchange(value = {}) {
        onChangeFunction({ "target": { "name": props.name, "value": value?.toString() } });
    }

    return (
        <Form.Group as={props.column ? Row : Col} xl={props.col ? props.col : 2}>
            <Form.Label column={props.column}>{translate(props.translation ? props.translation : props.name)}</Form.Label>
            <OptionalColumn column={props.column}>
                {(() => {
                    if (props.locked)
                        return (
                            <>
                                <br />
                                {field ? new Date(field).toLocaleDateString("de") : ""}
                            </>
                        )
                    else
                        return (
                            <DatePicker name={props.name} selected={field} key={props.key ? props.key : props.name} onChange={onchange} readOnly={props.readOnly || props.locked} dateFormat="dd.MM.yyyy" className="form-control" />
                        )
                })()}
            </OptionalColumn>
        </Form.Group>
    )
}

function TypeaheadInput(props) {
    const tab = useOpenTab()
    // console.log(props.options)
    const onChangeFunction = props.onChange("update");
    function onchange(value = {}) {
        // console.log(arguments)
        // console.log(value)
        onChangeFunction({ "target": { "name": props.name, "value": value } });
    }

    return (
        <Form.Group as={Col} xl={props.col ? props.col : 2}>
            <Form.Label>{translate(props.translation ? props.translation : props.name)}</Form.Label>
            {(() => {
                if (props.locked)
                    return (
                        <>
                            <br />
                            {(() => {
                                if (props?.selected?.[0] !== undefined) {
                                    if (props?.component !== undefined) {
                                        return (<Button variant="link" onClick={tab({ "component": props?.component, "data": { "id": props?.selected[0].id } })}>
                                            {props?.selected.map((value) => {
                                                return (translate(value[props.labelKey]))
                                            })}
                                        </Button>
                                        )
                                    }
                                    else {
                                        return (
                                            <>
                                                {props?.selected.forEach((value) => {
                                                    return (translate(value[props.labelKey]?.name))
                                                })}
                                            </>
                                        )
                                    }
                                }
                            })()}
                        </>
                    )
                else
                    return (
                        <Typeahead id={props.labelKey ? props.labelKey : "name"} labelKey={(option) => translate(option[props.labelKey ? props.labelKey : "name"])} onChange={onchange} options={props.options} placeholder={props.placeholder ? props.placeholder : ""} selected={props.selected} disabled={props.disabled} />
                    )
            })()}
        </Form.Group >
    )
}

export {
    TextInput,
    CheckboxInput,
    DropdownInput,
    TypeaheadInput,
    UnlockButton,
    DatePickerInput,
}