import Container from 'react-bootstrap/Container'
import { useSelector, useDispatch } from 'react-redux'
import { createSelector, createSlice } from '@reduxjs/toolkit'

import ContractDetails from './details'
import Overview from './overview'
import { useOpenTab } from '../tabs'

const contractSlice = createSlice({
    name: 'contracts',
    initialState: {},
    reducers: {
        addEventToHistory: (state, data) => state.find(contract => contract.id === data.payload.id).history.append({ ts: new Date().toISOString(), text: data.text }),
        removeContract: (state, data) => state.filter(contract => (contract.id !== data.payload.id)),
        updateContract: (state, data) => {
            const contract = state[data.payload.id]
            if (!("history" in contract))
                contract.history = []

            for (var prop in data.payload)
                if (contract[prop] !== data.payload[prop]) {
                    // contract.history.append({ ts: new Date().toISOString(), text: `updated ${Translation[prop]} from ${contract[prop]} to ${data.payload[prop]}` })
                    contract[prop] = data.payload[prop]
                }
        },
        addContract: (state, data) => {
            state[data.payload.id] = { ...data.payload }
        }
    }
})

export const SelectAllContracts = createSelector((state) => state.contracts, (contracts) => contracts);
export const SelectContractsForCustomer = (id) => createSelector((state) => state.contracts, (contracts) => {
    const result = {}
    Object.entries(contracts).map(([key, value]) => {
        if (value?.customer?.[0]?.id === id)
            result[key] = value
    })
    return result
})

export const { actions, reducer } = contractSlice
export const { removeContract, updateContract, addContract } = actions

export function useAddContractOnClick() {
    const tab = useOpenTab()
    const dispatch = useDispatch()
    const contracts = useSelector(SelectAllContracts)
    return (customer) => {
        return () => {
            const id = Math.max(...Object.keys(contracts || {}).map(Number), 0) + 1
            dispatch(addContract({ "id": id, "customer": [{ "id": customer.id, "name": customer.first_name + " " + customer.last_name }], "corporation": [], "status": "active" }))
            tab({ "component": "Contracts", "data": { "id": id, "locked": false } })()
        }
    }
}

export default function Layout(props) {
    const contracts = useSelector(SelectAllContracts);

    return (
        <Container fluid >
            {(() => {
                if (props && props.hasOwnProperty("id"))
                    return (
                        <ContractDetails {...props} />
                    )
                else
                    return (
                        <Overview data={contracts} />
                    )
            })()}
        </Container>
    )
}
