import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Row from 'react-bootstrap/Row';
import { BsBuilding, BsCalendar, BsCollection, BsPerson, BsColumnsGap, BsFileEarmark, BsEnvelope, BsClipboard, BsShuffle, BsGear, BsPlusSquare, BsPeople } from 'react-icons/bs'
import { VscOrganization } from 'react-icons/vsc'

import Authentication from './components/authentication'
import { useAddCorporationOnClick } from './components/corporations'
import { useAddCustomerOnClick } from './components/customers'
import { default as Timeline } from './components/timeline'
import SearchBar from './components/searchbar'
import { default as TabBar, useOpenTab } from './components/tabs'

import './app.css'

export default function Index() {
    // const addCustomer = useAddCustomerOnClick()
    const addCorporation = useAddCorporationOnClick()
    const tab = useOpenTab()

    return (
        <Authentication>
            <Container fluid >
                <Row>
                    <Col md={1} xl={1} className="sidebar-left">
                        <Nav defaultActiveKey="/home" className="flex-column">
                            <Nav.Link as={Button} variant="link" onClick={tab({ "component": "Dashboard" })}><BsColumnsGap className="nav-icon" />Dashboard</Nav.Link>
                            <Nav.Link as={Button} variant="link" onClick={tab({ "component": "Customers" })}><BsPerson className="nav-icon" />Kunden</Nav.Link>
                            <Nav.Link as={Button} variant="link" onClick={tab({ "component": "Corporations" })}><BsBuilding className="nav-icon" />Gesellschaften</Nav.Link>
                            <Nav.Link as={Button} variant="link" onClick={tab({ "component": "Contracts" })}><BsCollection className="nav-icon" />Verträge</Nav.Link>
                            <Nav.Link as={Button} variant="link" onClick={tab({ "component": "Documents" })}><BsFileEarmark className="nav-icon" />Dokumente</Nav.Link>
                            <Nav.Link as={Button} variant="link" onClick={tab({ "component": "Materialrisks" })}><BsClipboard className="nav-icon" />Sachrisiken</Nav.Link>
                            <Nav.Link as={Button} variant="link" onClick={tab({ "component": "Calender" })}><BsCalendar className="nav-icon" />Kalender</Nav.Link>
                            <Nav.Link as={Button} variant="link" onClick={tab({ "component": "Email" })}><BsEnvelope className="nav-icon" />Email</Nav.Link>
                            <Nav.Link as={Button} variant="link" onClick={tab({ "component": "Workflows" })}><BsShuffle className="nav-icon" />Prozesse</Nav.Link>
                            <Nav.Link as={Button} variant="link" onClick={tab({ "component": "Employees" })}><VscOrganization className="nav-icon" />Organisation</Nav.Link>
                            <Nav.Link as={Button} variant="link" onClick={tab({ "component": "Settings" })}><BsGear className="nav-icon" />Einstellungen</Nav.Link>
                        </Nav>
                    </Col>
                    <Col>
                        <Row className="bd-header">
                            <Col>
                                <Form.Group>
                                    <InputGroup size="lg">
                                        <NavDropdown title={<BsPlusSquare className="icon" />} id="nav-dropdown" size="lg">
                                            <NavDropdown.Item >Kunde hinzufügen</NavDropdown.Item>
                                            <NavDropdown.Item >Gesellschaft hinzufügen</NavDropdown.Item>
                                        </NavDropdown>
                                        <SearchBar />
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="content-wrapper">
                                <TabBar />
                            </Col>
                            <Col md={1} xl={3} className="sidebar-right">
                                {/* <Timeline /> */}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container >
        </Authentication >
    )
}
