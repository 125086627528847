export const Translation = {
    "assumed_inflation": "Angenommene Inflation",
    "bank_name": "Kreditinstitut",
    "bic": "BIC",
    "birth_date": "Geburtsdatum",
    "birth_place": "Geburtsort",
    "bycicle_insured": "Fahrrad abgesicher?",
    "bycicle_value": "Fahrrad Preis",
    "city": "Ort",
    "cluster": "Cluster",
    "collectables": "Sammlungen/Antiquitäten/Schmuck",
    "country": "Land",
    "current_pension_points_date": "Stand der Entgeltpunkte",
    "current_pension_points": "Aktuelle Entgeltpunkte",
    "customer_id": "Kundennummer",
    "customer_status_customer": "Kunde",
    "customer_status_passed_away": "Verstorben",
    "customer_status_potential": "Potenzial",
    "customer_status_prospect": "Interessent",
    "customer_status_retired": "Ausgeschieden",
    "customer_status": "Kundenstatus",
    "drivers_licence_date": "Führerscheindatum",
    "email": "Email",
    "employer": "Arbeitgeber",
    "experience_level": "Erfahrungsstufe",
    "fax_number": "Fax",
    "first_name": "Vorname",
    "forecast_emr": "Aktueller EMR Anspruch",
    "forecast_pension_points": "Prognose mit 67",
    "formal": "Per du",
    "grosswage": "Brutto mtl.",
    "health_issues": "Gesundheitliche Probleme",
    "height": "Größe",
    "highest_education": "Höchster Bildungsabschluss",
    "hobbys": "Hobbys",
    "house_number": "Hausnummer",
    "iban": "IBAN",
    "job_status_employed_dual_student": "Dualer Student",
    "job_status_employed_o_d": "Angestellt ö.D.",
    "job_status_employed_pensioner": "Rentner",
    "job_status_employed_pupil": "Schüler",
    "job_status_employed_student": "Student",
    "job_status_employed_unemployed": "Arbeitslos",
    "job_status_employed": "Angestellt",
    "job_status_official": "Beamter",
    "job_status_self_employed": "Selbständig",
    "job_status": "Berufsstatus",
    "job": "Beruf",
    "last_name": "Name",
    "living_situation_property": "Eigentum",
    "living_situation_rent": "Miete",
    "living_situation": "Wohnsituation",
    "martial_arts": "Kampfsport",
    "martial_status_divorced": "geschieden",
    "martial_status_married": "verheiratet",
    "martial_status_single": "ledig",
    "martial_status_widowed": "verwitwet",
    "martial_status": "Familienstand",
    "mobile_number": "Mobilnummer",
    "motor_cyclist": "Motorradfahren",
    "motor_vehicles": "KFZ",
    "nationality": "Nationalität",
    "nettwage": "Netto mtl.",
    "contract_number": "Vertragsnummer",
    "status": "Status",
    "branch": "Sparte",
    "qm_living_space_cellar": "Davon im Keller",
    "insurance_holder": "Versicherungsnehmer",
    "insured_person": "Versicherte Person",
    "product": "Produkt",
    "rate": "Tarif",
    "premium": "Prämie",
    "application_date": "Antragsdatum",
    "policy_date": "Policierungsdatum",
    "start_date": "Beginndatum",
    "expiration_date": "Ablaufdatum",
    "corporation_name": "Gesellschaftsname",
    "corporation": "Gesellschaft",
    "broker_number": "Vermittlernummer",
    "corporation_id": "Gesellschaftsnummer",
    "number_of_salaries": "Anzahl Gehälter",
    "other_hobbys": "Sonstige Hobbies",
    "other_valuables": "Sonstiges Vermögen",
    "percentage_of_office_work_10": "10%",
    "percentage_of_office_work_100": "100%",
    "percentage_of_office_work_20": "20%",
    "percentage_of_office_work_30": "30%",
    "percentage_of_office_work_40": "40%",
    "percentage_of_office_work_50": "50%",
    "percentage_of_office_work_60": "60%",
    "percentage_of_office_work_70": "70%",
    "percentage_of_office_work_80": "80%",
    "percentage_of_office_work_90": "90%",
    "percentage_of_office_work": "Anteil Büro Tätigkeit",
    "percentage_of_physical_work_10": "10%",
    "percentage_of_physical_work_100": "100%",
    "percentage_of_physical_work_20": "20%",
    "percentage_of_physical_work_30": "30%",
    "percentage_of_physical_work_40": "40%",
    "percentage_of_physical_work_50": "50%",
    "percentage_of_physical_work_60": "60%",
    "percentage_of_physical_work_70": "70%",
    "percentage_of_physical_work_80": "80%",
    "percentage_of_physical_work_90": "90%",
    "percentage_of_physical_work": "Anteil körperliche Arbeit",
    "pets": "Tiere/Haustiere",
    "vehicle_category": "Art",
    "vehicle_license_plate": "Kennzeichen",
    "vehicle_comment": "Bemerkung",
    "phone_number": "Telefonnummer",
    "postal_code": "Postleitzahl",
    "qm_cellar_space": "Gesamte Kellerfläche (qm)",
    "qm_living_space": "Gesamte Wohnfläche (qm)",
    "real_estate_category": "Art",
    "real_estate_city": "Ort",
    "real_estate_comment": "Bemerkung",
    "real_estate_description": "Beschreibung",
    "description": "Beschreibung",
    "real_estate_house_number": "Hausnummer",
    "real_estate_postal_code": "Postleitzahl",
    "real_estate_qm": "Quadratmeter",
    "real_estate_street": "Stasse",
    "real_estate": "Immobilien (Bezeichnung + Anschrift Art(Liste) + Fläche + Wert + Restschuld + Bruttokaltmiete + BAK(Liste))",
    "remarks_hobbys": "Sonstige Bemerkungen",
    "salary_group": "Besoldungsgruppe",
    "salutation_company": "Firma",
    "salutation_diverse": "Divers",
    "salutation_madam": "Frau",
    "salutation_sir": "Herr",
    "salutation": "Anrede",
    "smoker": "Raucher",
    "social_security_number": "Sozialversicherungsnummer",
    "solar_installation": "Eigene Solaranlage?",
    "state": "Bundesland",
    "street": "Straße",
    "target_pension_age": "Wunschrentenalter",
    "target_pension_salary": "Wunschrentenhöhe nach heutiger Kaufkraft",
    "tax_group": "Steuerklasse",
    "tax_id": "Steuer ID",
    "titel": "Titel",
    "weight": "Gewicht",
    "working_since": "Berufstätig seit (inkl. Ausbildung)",
    "building_type": "Art",
    "construction_year": "Baujahr",
    "core_renovation": "Jahr der letzten Kernsanierung",
    "number_residential_units": "Anzahl Wohneinheiten",
    "number_residential_floors": "Anzahl Wohngeschosse",
    "qm_swimming_space": "Nutzfläche Schwimmbad im Gebäude",
    "qm_alternative_space": "Hobby-/Wohnfläche Nebengebäude",
    "renovation_wireing": "Sanierung Stromleitungen",
    "renovation_roof": "Sanierung Dach",
    "renovation_heater": "Sanierung Heizung",
    "renovation_supply_pipes": "Sanierung Zuleitungen",
    "renovation_waste_pipes": "Sanierung Abwasserleitungen",
    "type": "Art",
    "tenement": "Mietswohnung",
    "condominium": "Eigentumswohnung",
    "bicycle": "Fahrrad",
    "car": "KFZ",
    "pet": "Tier",
    "valueable": "Wertsache",
    "number_carports": "Anzahl Carports",
    "number_garage": "Anzahl Garagen",
    "roof": "Dach",
    "furnishing": "Bauausstattung",
    "bak": "BAK",
    "license_plate": "Kennzeichen",
    "hsn": "HSN",
    "tsn": "TSN",
    "milage": "Km-Stand",
    "milage_date": "Datum km-Stand",
    "initial_registration_date": "Datum Erstzulassung",
    "registration_date": "Datum Zulassung auf Halter",
    "is_first_owner": "Halter ist Erstbesitzer",
    "immobilizer": "Wegfahrsperre",
    "price": "Kaufpreis",
    "yearly_mileage": "Fahrleistung p.a.",
    "night_parking": "Nächtl. Abstellp.",
    "lane_assist": "Spurhalteassistent",
    "turn_assist": "Abbiegeassistent",
    "adaptive_cruise_control": "Abstandsregeltempomat",
    "emergency_brake_assist": "Notbremsassistent",
    "financing": "Finanzierung d. Fahrzeugs",
    "vehicle_usage": "Nutzung d. Fahrzeugs",
    "betrag": "Betr. Ausg.",
    "name": "Name",
    "chip_id": "Chip/Identifikationsnummer",
    "relation.name": "Zuordnung",
    "id_card": "Personalausweis",
    "id_card_number": "Personalausweisnummer",
    "passport": "Reisepass",
    "policy": "Police",
    "offer": "Angebot",
    "correspondence": "Schriftwechsel",
    "application": "Antrag",
    "brokerage_agreement": "Maklervertrag",
    "power_of_attorney": "Maklervollmacht",
    "power_of_attorney_for_information": "Auskunftsvollmacht",
    "consulting_documentation": "Beratungsdokumentation",
    "privacy_policy": "Datenschutzerklärung",
    "other": "Sonstiges",
    "date_of_issue": "Ausstellungsdatum",
    "issuing_authority": "Ausstellende Behörde",
    "note": "Bemerkung",
    "adding_date": "Hinzugefügt am",
    "Contracts": "Verträge",
    "Documents": "Dokumente",
    "Workflows": "Prozesse",
}

function translate(name) {
    if (Translation.hasOwnProperty(name))
        return Translation[name];
    return name;
}

export default Translation;
export { translate }