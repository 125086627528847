import { RiArrowDropRightFill } from 'react-icons/ri'
import { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'

import { translate } from '../../translation'
import { useOpenTab } from '../tabs'

export default function Layout(props) {
    const tab = useOpenTab()
    const [checkboxes, setCheckboxes] = useState({})

    function handleClick(e) {
        const name = e.target.name
        const checked = e.target.checked

        if (name === "checkall") {
            const state = { "checkall": checked }
            Object.entries(props.data || {}).map(([key, value]) => {
                state[value.id] = checked
            })
            setCheckboxes(state)
        } else {
            const state = { ...checkboxes }
            state[name] = checked
            if (!checked)
                state["checkall"] = false
            setCheckboxes(state)
        }
    }

    return (
        <Table className="table" >
            <thead>
                <tr>
                    {(() => {
                        if (props.checkbox)
                            return (<th><Form.Check checked={checkboxes[`checkall`]} onClick={handleClick} type="checkbox" name="checkall" onChange={props.onChange("update")} /></th>)
                    })()}
                    <th scope="col">Bezeichnung</th>
                    <th scope="col">Art</th>
                    <th scope="col">Zuordnung</th>
                    <th scope="col">Datum</th>
                    <th scope="col">Status</th>
                </tr>
            </thead>
            <tbody>
                {Object.entries(props.data || {}).map(([, value]) => {
                    return (
                        <tr>
                            {(() => {
                                if (props.checkbox)
                                    return (<td><Form.Check checked={checkboxes[value.id]} onClick={handleClick} type="checkbox" name={`documents-checkbox-${value.id}`} onChange={props.onChange("update")} /></td>)
                            })()}
                            <td><Button variant="link" onClick={tab({ "component": "Documents", "data": { "id": value.id } })} ><RiArrowDropRightFill />{value.file_name}</Button></td>
                            <td><Button variant="link" onClick={tab({ "component": "Documents", "data": { "id": value.id } })}>{translate(value.type)}</Button></td>
                            <td>
                                {(() => {
                                    if (value.relation)
                                        return <Button variant="link" onClick={tab({ "component": "Contracts", "data": { "id": value.relation.id } })}>{value.relation.name}</Button>
                                    else
                                        return value.relation
                                })()}
                            </td>
                            <td><Button variant="link" onClick={tab({ "component": "Documents", "data": { "id": value.id } })}>{new Date(value.update_date).toLocaleDateString("de")}</Button></td>
                            <td><Button variant="link" onClick={tab({ "component": "Documents", "data": { "id": value.id } })}>{value.status}</Button></td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
    )
}