import SigninLayout from '../signin'
import { useGetUserSelfQuery } from '../../server_api.js'

export default function Layout(props) {
    const { data, loading } = useGetUserSelfQuery()

    if (loading) {
        return (
            <>
                <div>X:{loading}</div>
                Loading ...
            </>
        )
    }

    if (data) {
        return (
            <>
                {props.children}
            </>
        )
    }

    return (
        <>
            <div>X:{loading}</div>
            <SigninLayout />
        </>
    )
}
