import Form from 'react-bootstrap/Form'
import { TextInput, DropdownInput, DatePickerInput } from '../../TextInput'

export default function Layout(props) {
    return (
        <>
            <Form>
                <DropdownInput name="salutation" options={["salutation_sir", "salutation_madam", "salutation_diverse", "salutation_company"]} {...props} column />
                <TextInput name="titel" {...props} column />
                <TextInput name="last_name" {...props} column />
                <TextInput name="first_name" {...props} column />
                <DatePickerInput name="birth_date" {...props} column />
                <TextInput name="birth_place" {...props} column />
                <TextInput name="nationality" {...props} column />
            </Form>
        </>
    )
}