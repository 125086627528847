import { BsGrid1X2, BsJustify } from 'react-icons/bs';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RiArrowDropRightFill } from 'react-icons/ri';
import { useSelector, useDispatch } from 'react-redux';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import OrganizationChart from "@dabeng/react-orgchart";
import Table from 'react-bootstrap/Table';

import EmployeeDetails from './details';
import { useOpenTab } from '../tabs';
import MyNode from "./orgchart_node";


const employeeSlice = createSlice({
    name: 'employees',
    initialState: {},
    reducers: {
        addEventToHistory: (state, data) => state.find(employee => employee.id === data.payload.id).history.append({ ts: new Date().toISOString(), text: data.text }),
        removeEmployee: (state, data) => state.filter(employee => (employee.firstname !== data.payload.firstname) && (employee.lastname !== data.payload.lastname)),
        updateEmployee: (state, data) => {
            const employee = state[data.payload.id];
            if (!("history" in employee))
                employee.history = []

            for (var prop in data.payload)
                if (employee[prop] !== data.payload[prop]) {
                    // employee.history.append({ ts: new Date().toISOString(), text: `updated ${Translation[prop]} from ${employee[prop]} to ${data.payload[prop]}` })
                    employee[prop] = data.payload[prop]
                }
        },
        addEmployee: (state, data) => {
            state[data.payload] = { "id": data.payload }
        }
    }
})

export const SelectAllEmployees = createSelector((state) => state.employees, (employees) => employees);

export const { actions, reducer } = employeeSlice
export const { removeEmployee, updateEmployee, addEmployee } = actions

export function useAddEmployeeOnClick() {
    const dispatch = useDispatch()
    const tab = useOpenTab()
    const employees = useSelector(SelectAllEmployees)
    return () => {
        const id = Math.max(...Object.keys(employees || {}).map(Number), 0) + 1
        dispatch(addEmployee(id))
        tab({ "component": "Employees", "data": { "id": id, "locked": false } })()
    }
}

export default function Layout(props) {
    const employees = useSelector(SelectAllEmployees)
    const tab = useOpenTab()
    const [toggleOrg, setToggleOrg] = useState(true)
    let root
    Object.values(employees).map((value) => {
        if (value.root)
            root = value
    })

    // TODO remove deep copy
    let copiedObject = JSON.parse(JSON.stringify(root))
    const ToggleButtonIcon = toggleOrg ? BsJustify : BsGrid1X2

    return (
        <Container fluid>
            {(() => {
                if (props && props.hasOwnProperty("id"))
                    return (
                        <EmployeeDetails {...props} />
                    )
                else
                    return (
                        <>
                            <Button variant="link" onClick={() => setToggleOrg(!toggleOrg)}><ToggleButtonIcon /></Button>
                            {/* <Nav variant="pills">
                                <NavDropdown title={<BsPlusSquare />} id="nav-dropdown"></NavDropdown>
                            </Nav> */}
                            {(() => {
                                if (toggleOrg)
                                    return (
                                        <OrganizationChart datasource={copiedObject} draggable={true} chartClass="myChart" NodeTemplate={MyNode} />
                                    )
                                else
                                    return (
                                        <Table className="table" >
                                            <thead>
                                                <tr>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Vorname</th>
                                                    <th scope="col">Geburtsdatum</th>
                                                    <th scope="col">Ort</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Object.entries(employees).map(([key, employee]) => {
                                                    return (
                                                        <tr key={employee.id}>
                                                            <td><Button variant="link" onClick={tab({ "data": { "id": employee.id } })}><RiArrowDropRightFill /> {employee.last_name}</Button></td>
                                                            <td><Button variant="link" onClick={tab({ "data": { "id": employee.id } })}>{employee.first_name}</Button></td>
                                                            <td><Button variant="link" onClick={tab({ "data": { "id": employee.id } })}>{new Date(employee.birth_date).toLocaleDateString("de")}</Button></td>
                                                            <td><Button variant="link" onClick={tab({ "data": { "id": employee.id } })}>{employee.city}</Button></td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                    )
                            })()}
                        </>
                    )
            })()}
        </Container >
    )
}
