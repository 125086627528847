import Container from 'react-bootstrap/Container'

import Move from './move'
import NameChange from './name_change'
import BaseData from './base_data'

export default function Layout(props) {
    console.log(props)
    function x() {

    }
    // zeige die aktuellen verträge und dokumente an
    // standardmäsig aktuelles marklermandat anzeigen
    let state = {}
    return (
        <>
            <Container fluid >
                {(() => {
                    switch (props.workflow) {
                        case "move": return <Move state={state} onChange={x} {...props} />
                        case "name_change": return <NameChange state={state} onChange={x} {...props} />
                        case "base_data": return <BaseData state={state} onChange={x} {...props} />
                        default: return (<div>Konzept für neuen Kunden erstellen</div>);
                    }
                })()}
            </Container>
        </>
    )
}
