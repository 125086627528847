import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { TextInput, CheckboxInput, DropdownInput, DatePickerInput, TypeaheadInput } from '../../TextInput'
import { SelectAllEmployees } from '../../employees'
import { useSelector, useDispatch } from 'react-redux'

export default function Layout(props) {
    const employees = useSelector(SelectAllEmployees);

    return (
        <>
            <Row>
                <TextInput name="id" {...props} readOnly />
                <TextInput name="cluster" {...props} col="1" />
                <DropdownInput name="customer_status" {...props} options={["customer_status_potential", "customer_status_prospect", "customer_status_customer", "customer_status_retired", "customer_status_passed_away"]} />
                <CheckboxInput name="formal" {...props} />
                <TypeaheadInput name="broker" translation="broker" col="2" options={Object.values(employees)} labelKey="name" selected={props.state.broker} component="Employees" {...props} />
            </Row>
            <Row>
                <DropdownInput name="salutation" col="1" options={["salutation_sir", "salutation_madam", "salutation_diverse", "salutation_company"]} {...props} />
                <TextInput name="titel" col="1" {...props} />
            </Row>
            <Row>
                <TextInput name="last_name" {...props} />
                <TextInput name="first_name" {...props} />
                <Col xl="1" ></Col>
                <DatePickerInput name="birth_date" {...props} col="1" />
                <TextInput name="birth_place" {...props} />
            </Row>
            <Row>
                <TextInput name="street" {...props} col="3" />
                <TextInput name="house_number" {...props} col="1" />
                <Col xl="1" ></Col>
                <DropdownInput name="martial_status" col="1" options={["martial_status_single", "martial_status_married", "martial_status_divorced", "martial_status_widowed"]} {...props} />
                <TextInput name="nationality" {...props} />
            </Row>
            <Row>
                <TextInput name="postal_code" {...props} col="1" />
                <TextInput name="city" {...props} />
            </Row>
            <Row>
                <TextInput name="state" {...props} />
                <TextInput name="country" {...props} />
            </Row>
            <Row>
                <TextInput name="mobile_number" {...props} />
                <TextInput name="phone_number" {...props} />
            </Row>
            <Row>
                <TextInput name="fax_number" {...props} />
                <TextInput name="email" {...props} col="3" />
            </Row>
        </>
    )
}
