import Row from 'react-bootstrap/Row';
import { TextInput, DropdownInput, CheckboxInput } from '../../TextInput'

export default function Layout(props) {
    return (
        <>
            <Row>
                <TextInput name="street" col="2" {...props} />
                <TextInput name="house_number" col="1" {...props} />
            </Row>
            <Row>
                <TextInput name="postal_code" col="1" {...props} />
                <TextInput name="city" col="1" {...props} />
            </Row>
            <Row>
                <DropdownInput name="building_type" options={["Einfailienhaus", "Mehrfamilienhaus", "Doppelhaushälfte", "Wohnung"]} col="1" {...props} />
                <TextInput name="construction_year" col="1" {...props} />
                <TextInput name="core_renovation" col="1" {...props} />
                <TextInput name="number_residential_units" col="1" {...props} />
                <TextInput name="number_residential_floors" col="1" {...props} />
            </Row>
            <Row>
                <CheckboxInput name="renovation_wireing" col="1" {...props} />
                <CheckboxInput name="renovation_roof" col="1" {...props} />
                <CheckboxInput name="renovation_heater" col="1" {...props} />
                <CheckboxInput name="renovation_supply_pipes" col="1" {...props} />
                <CheckboxInput name="renovation_waste_pipes" col="1" {...props} />
            </Row>
            <Row>
                <TextInput name="qm_living_space" col="1" {...props} />
                <TextInput name="qm_living_space_cellar" col="1" {...props} />
                <TextInput name="qm_cellar_space" col="1" {...props} />
                <TextInput name="qm_swimming_space" col="1" {...props} />
                <TextInput name="qm_alternative_space" col="1" {...props} />
            </Row>
            <Row>
                <TextInput name="number_carports" col="1" {...props} />
                <TextInput name="number_garage" col="1" {...props} />
                <DropdownInput name="roof" options={["Giebeldach", "Flachdach"]} col="1" {...props} />
                <DropdownInput name="furnishing" options={["Normal", "Gehoben"]} col="1" {...props} />
                <DropdownInput name="bak" options={["1", "2", "3", "4",]} col="1" {...props} />
            </Row>
        </>
    )
}