import Row from 'react-bootstrap/Row';
import { TextInput } from '../../TextInput'

export default function Layout(props) {
    return (
        <>
            <Row>
                <TextInput name="price" col="2" {...props} />
            </Row>
        </>
    )
}