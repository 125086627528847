import { createSelector, createSlice } from '@reduxjs/toolkit'
import { useSelector, useDispatch } from 'react-redux'
import Container from 'react-bootstrap/Container'

import MaterialriskDetails from './details'
import Overview from './overview'
import { useOpenTab } from '../tabs'

const materialriskSlice = createSlice({
    name: 'materialrisks',
    initialState: {},
    reducers: {
        addEventToHistory: (state, data) => state.find(materialrisk => materialrisk.id === data.payload.id).history.append({ ts: new Date().toISOString(), text: data.text }),
        removeMaterialrisk: (state, data) => state.filter(materialrisk => (materialrisk.id !== data.payload.id)),
        updateMaterialrisk: (state, data) => {
            const materialrisk = state[data.payload.id]
            if (!("history" in materialrisk))
                materialrisk.history = []

            for (var prop in data.payload)
                if (materialrisk[prop] !== data.payload[prop]) {
                    // materialrisk.history.append({ ts: new Date().toISOString(), text: `updated ${Translation[prop]} from ${materialrisk[prop]} to ${data.payload[prop]}` })
                    materialrisk[prop] = data.payload[prop]
                }
        },
        addMaterialrisk: (state, data) => {
            state[data.payload.id] = { ...data.payload }
        }
    }
})

export const SelectAllMaterialrisks = createSelector((state) => state.materialrisks, (materialrisks) => materialrisks);
export const SelectMaterialrisksForCustomer = (id) => createSelector((state) => state.materialrisks, (materialrisks) => {
    const result = {}
    Object.entries(materialrisks).map(([key, value]) => {
        if (value?.customer?.[0]?.id === id)
            result[key] = value
    })
    return result
})

export const { actions, reducer } = materialriskSlice
export const { removeMaterialrisk, updateMaterialrisk, addMaterialrisk } = actions

export function useAddMaterialriskOnClick() {
    const dispatch = useDispatch();
    const documents = useSelector(SelectAllMaterialrisks);
    const tab = useOpenTab()
    return (customer) => {
        return () => {
            const id = Math.max(...Object.keys(documents || {}).map(Number), 0) + 1
            dispatch(addMaterialrisk({ "id": id, "customer": [{ "id": customer.id, "name": customer.first_name + " " + customer.last_name }], "status": "Aktiv" }))
            tab({ "component": "Materialrisks", "data": { "id": id, "locked": false } })()
        }
    }
}

export default function Layout(props) {
    const materialrisks = useSelector(SelectAllMaterialrisks);

    return (
        <Container fluid >
            {(() => {
                if (props && props.hasOwnProperty("id"))
                    return (
                        <MaterialriskDetails {...props} />
                    )
                else
                    return (
                        <Overview data={materialrisks} />
                    )
            })()}
        </Container>
    )
}
