import { useDispatch } from 'react-redux'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';

import { api, useGetUserSelfQuery, useSignInMutation } from '../../server_api.js';
import { setCredentials } from '../authentication/slice.js';

export default function Layout(props) {
    const dispatch = useDispatch()
    const [email, setEmail] = useState('test@test.de');
    const [password, setPassword] = useState('test1234');
    const [signIn] = useSignInMutation();
    const { refresh } = useGetUserSelfQuery()


    return (
        <Container fluid >
            <Form.Group>
                <Row>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </Form.Group>
                </Row>
                <br />
                <Row>
                    <Button name="submit" type="submit" col="2" onClick={async () => {
                        try {
                            const response = await signIn({ email, password }).unwrap()
                            dispatch(setCredentials(response.access_token))
                            dispatch(api.endpoints.getUserSelf.initiate(undefined, { subscribe: false, forceRefetch: true }))
                        } catch (error) {

                        }
                    }} >Signin</Button>
                </Row>
            </Form.Group>
        </Container >
    )
}
