import { createSelector } from '@reduxjs/toolkit'
import { useSelector, useDispatch } from 'react-redux'
import { useState, useReducer, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import produce from 'immer'

import { updateCustomer } from '../customers'
import Page1 from './first_interview/page1'

const SelectAllCustomers = createSelector((state) => state.customers, (customers) => customers);

function updateCustomerReducer(draft, field, value) {
    const path = field.split(".")
    const last_item = path.pop()
    let path_to_object = draft
    for (let i in path)
        path_to_object = path_to_object[path[i]]
    path_to_object[last_item] = value
}

export default function Layout(props) {
    const dispatch = useDispatch();
    const customers = useSelector(SelectAllCustomers);
    // console.log(props.id)
    const customer = customers[props.id] || { id: 0 };
    const reducer = (draft, { field, value, type }) => {
        switch (type) {
            case "update":
                return updateCustomerReducer(draft, field, value)
            case "reinit":
                return value
            default:
                console.error("Unknown type: " + type)
                return draft;
        }
    };
    const [timer, setTimer] = useState(null);
    const [isChanged, setIsChanged] = useState(false);
    const [currentPage, setCurrentPage] = useState("Page1");
    const [state, setState] = useReducer(produce(reducer), customer);
    if (state.id !== customer.id)
        setState({ value: customer, type: "reinit" })

    useEffect(() => {
        if (!isChanged)
            return

        clearTimeout(timer)
        setTimer(setTimeout(() => {
            dispatch(updateCustomer(state))
        }, 2000))
        setIsChanged(false)
    }, [isChanged, setIsChanged, timer, setTimer, state, dispatch]);

    const onChange = (type) => {
        return (e) => {
            setIsChanged(true);
            setState({ field: e.target.name, value: e.target.value, type: type });
        }
    }

    function onClickChangePage(value) {
        return (e) => {
            if (value === "Next") {
                switch (currentPage) {
                    case "Page1": return setCurrentPage("Page2")
                    case "Page2": return setCurrentPage("Page3")
                    case "Page3": return setCurrentPage("Page4")
                    case "Page4": return setCurrentPage("Page5")
                    case "Page5": return setCurrentPage("Page1")
                    default: return setCurrentPage("Page1")
                }
            }
            if (value === "Previous") {
                switch (currentPage) {
                    case "Page1": return setCurrentPage("Page5")
                    case "Page2": return setCurrentPage("Page1")
                    case "Page3": return setCurrentPage("Page2")
                    case "Page4": return setCurrentPage("Page3")
                    case "Page5": return setCurrentPage("Page4")
                    default: return setCurrentPage("Page1")
                }
            }
            setCurrentPage(value);
        }
    }

    return (
        <>
            <Button variant="link" onClick={onClickChangePage("Page1")}>Grunddaten</Button>
            <Button variant="link" onClick={onClickChangePage("Page2")}>Anschrift</Button>
            <Button variant="link" onClick={onClickChangePage("Page3")}>Erreichbarkeit</Button>
            <Button variant="link" onClick={onClickChangePage("Page4")}>Familiäre Situation</Button>
            <Button variant="link" onClick={onClickChangePage("Page5")}>Beratungsumfang</Button>
            {
                (() => {
                    switch (currentPage) {
                        case "Page1": return <Page1 {...props} state={state} onChange={onChange} />
                        case "Page2": return <Page1 {...props} state={state} onChange={onChange} />
                        case "Page3": return <Page1 {...props} state={state} onChange={onChange} />
                        case "Page4": return <Page1 {...props} state={state} onChange={onChange} />
                        case "Page5": return <Page1 {...props} state={state} onChange={onChange} />
                        default: return <Page1 {...props} state={state} onChange={onChange} />
                    }
                })()
            }
            <Button variant="link" onClick={onClickChangePage("Previous")}>Zurück</Button>
            <Button variant="link" onClick={onClickChangePage("Next")}>Weiter</Button>
        </>
    )
}