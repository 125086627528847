import { BsPlusSquare, BsShuffle } from 'react-icons/bs'
import { createSelector } from '@reduxjs/toolkit'
import { useSelector, useDispatch } from 'react-redux'
import { useState, useReducer, useEffect } from 'react';
import Form from 'react-bootstrap/Form'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import produce from 'immer'

import { updateCustomer } from '../index'
import JobAndSalary from './JobAndSalary'
import PersonalRisk from './PersonalRisk'
import MaterialRisk from './MaterialRisk'
import MaterialRiskOverview from '../../materialrisks/overview'
import DocumentsOverview from '../../documents/overview'
import { useAddDocumentOnClick, SelectDocumentsForCustomer } from '../../documents'
import { useAddMaterialriskOnClick, SelectMaterialrisksForCustomer } from '../../materialrisks'
import { useAddContractOnClick, SelectContractsForCustomer } from '../../contracts'
import { useOpenTab } from '../../tabs'
import ContractsOverview from '../../contracts/overview'
import Pension from './Pension'
import Personal from './Personal'
import { UnlockButton } from '../../TextInput';
import { useGetCustomerQuery } from 'server_api.js'


const SelectAllCustomers = createSelector((state) => state.datas, (datas) => datas);

const merge = (target, source) => {
    for (const key of Object.keys(source))
        if (source[key] instanceof Object) Object.assign(source[key], merge(target[key], source[key]))
    Object.assign(target || {}, source)
    return target
}

function updateCustomerReducer(draft, field, value) {
    const path = field.split(".")
    const last_item = path.pop()
    let path_to_object = draft
    for (let i in path)
        path_to_object = path_to_object[path[i]]
    path_to_object[last_item] = value
}

export default function Layout(props) {
    // const dispatch = useDispatch();
    // const customers = useSelector(SelectAllCustomers);
    // // console.log(props.id)
    // const customer = customers[props.id] || { id: 0 };
    const reducer = (draft, { field, value, type }) => {
        switch (type) {
            case "addRealEstate":
                draft.real_estate = draft.real_estate || {}
                draft.real_estate[Math.max(...Object.keys(draft.real_estate || {}).map(Number), 0) + 1] = {}
                break
            case "removeRealEstate":
                delete draft.real_estate[value];
                break
            case "addVehicle":
                draft.vehicles = draft.vehicles || {}
                draft.vehicles[Math.max(...Object.keys(draft.vehicles).map(Number), 0) + 1] = {}
                break
            case "removeVehicle":
                delete draft.vehicles[value];
                break
            case "update":
                return updateCustomerReducer(draft, field, value)
            case "reinit":
                return value
            default:
                console.error("Unknown type: " + type)
                return draft;
        }
    };
    const { data = {}, error, loading } = useGetCustomerQuery(props.id)
    // const [timer, setTimer] = useState(null);
    // const [isChanged, setIsChanged] = useState(false);
    const [state, setState] = useReducer(produce(reducer), data);
    if (state.id !== data.id)
        setState({ value: data, type: "reinit" })

    // useEffect(() => {
    //     if (!isChanged)
    //         return

    //     clearTimeout(timer)
    //     setTimer(setTimeout(() => {
    //         dispatch(updateCustomer(state))
    //     }, 2000))
    //     setIsChanged(false)
    // }, [isChanged, setIsChanged, timer, setTimer, state, dispatch]);

    const onChange = (type) => {
        return (e) => {
            // setIsChanged(true);
            // setState({ field: e.target.name, value: e.target.value, type: type });
        }
    }




    const addDocument = useAddDocumentOnClick({ "id": data.id, "name": data.first_name + " " + data.last_name })
    const documents = useSelector(SelectDocumentsForCustomer(data.id));
    const addMaterialrisk = useAddMaterialriskOnClick({ "id": data.id, "name": data.first_name + " " + data.last_name })
    const materialrisks = useSelector(SelectMaterialrisksForCustomer(data.id));
    const addContract = useAddContractOnClick({ "id": data.id, "name": data.first_name + " " + data.last_name })
    const contracts = useSelector(SelectContractsForCustomer(data.id));
    const tab = useOpenTab()

    return (
        <>
            <Nav variant="pills">
                <UnlockButton {...props} />
                <NavDropdown title={<BsPlusSquare />} id="nav-dropdown">
                    <NavDropdown.Item onClick={addContract(data)}>Vertrag hinzufügen</NavDropdown.Item>
                    <NavDropdown.Item onClick={addDocument(data)}>Dokument hinzufügen</NavDropdown.Item>
                    <NavDropdown.Item onClick={addMaterialrisk(data)}>Sachrisiko hinzufügen</NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title={<BsShuffle />} id="nav-dropdown">
                    <NavDropdown.Item onClick={tab({ "component": "Workflows", "data": { "id": data.id, "workflow": "move", "customer": data, "locked": false } })}>Umzug</NavDropdown.Item>
                    <NavDropdown.Item onClick={tab({ "component": "Workflows", "data": { "id": data.id, "workflow": "name_change", "customer": data, "locked": false } })}>Name ändern</NavDropdown.Item>
                    <NavDropdown.Item onClick={tab({ "component": "Workflows", "data": { "id": data.id, "workflow": "bank_change", "customer": data, "locked": false } })}>Kontowechsel</NavDropdown.Item>
                </NavDropdown>
            </Nav>

            <Form id="mainform">
                <Personal state={state} onChange={onChange} {...props} />
                <br />
                Verträge
                {/* <ContractsOverview data={contracts} {...props} /> */}
                Dokumente
                {/* <DocumentsOverview data={documents} {...props} /> */}
                Sachrisiken
                {/* <MaterialRiskOverview data={materialrisks} {...props} /> */}
                {/* <JobAndSalary state={state} onChange={onChange} {...props} /><br /> */}
                {/* <JobAndSalary {...props} /><br /> */}
                {/* <PersonalRisk state={state} onChange={onChange} {...props} /><br /> */}
                {/* <MaterialRisk state={state} onChange={onChange} {...props} /><br />
                <Pension state={state} onChange={onChange} {...props} /> */}
                <br />
                <br />
                <br />
                <br />
                <br />
            </Form>
        </>
    )
}
