import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'user',
    initialState: {
        access_token: localStorage.getItem('access_token')
    },
    reducers: {
        setCredentials: (state, { payload }) => {
            state.access_token = payload
            localStorage.setItem('access_token', payload);
        },
    },
});

export const { setCredentials } = slice.actions
export default slice.reducer