import { BsXCircle } from 'react-icons/bs'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { default as React } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import Row from 'react-bootstrap/Row'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'

import { translate } from '../../translation'
import { default as Calendar } from '../calendar'
import { default as Contracts } from '../contracts'
import { default as Corporations } from '../corporations'
import { default as Customers } from '../customers'
import { default as Dashboard } from '../dashboard'
import { default as Settings } from '../settings'
import { default as Documents } from '../documents'
import { default as Materialrisks } from '../materialrisks'
import { default as Workflows } from '../workflows'
import { default as Employees } from '../employees'

function close_tab_impl(state, data) {
    const current_focus_tab = state.focused_tab
    const tab_to_close = data.payload.id
    if (current_focus_tab === data.payload.id) {
        if (state.list.hasOwnProperty(state.list[current_focus_tab].last_tab) && state.list[current_focus_tab].last_tab !== current_focus_tab) {
            state.focused_tab = state.list[current_focus_tab].last_tab
            delete state.list[tab_to_close]
        } else {
            delete state.list[tab_to_close]
            if (Object.keys(state.list).length !== 0) {
                state.focused_tab = "" + Math.max(...Object.keys(state.list))
            } else
                state.focused_tab = ""
        }
    }
}

function open_new_tab(state, data) {
    // console.log(Object.keys(state.list || {}).map(Number))
    const index = "" + (Math.max(...Object.keys(state.list || {}).map(Number), 0) + 1)
    // console.log(index)
    state.list[index] = {
        "title": data.payload?.title ? data.payload?.title : data.payload.component,
        "component": data.payload.component,
        "last_tab": state.focused_tab,
        "data": {
            "locked": true,
            ...data.payload.data
        }
    }
    state.focused_tab = index
}

function update_current_tab(state, data) {
    update_tab(state, state.list[state.focused_tab], data)
}

function update_tab(state, tab, data) {
    tab.data = {
        ...tab.data,
        ...data.payload.data,
    }
    tab.last_tab = state.focused_tab

    if (data.payload?.title)
        tab.title = data.payload.title
    if (data.payload?.component)
        tab.component = data.payload.component
}

const tabSlice = createSlice({
    name: 'tabs',
    initialState: {
        "list": {},
        "focused_tab": "1",
        "component_tabs": {}
    },
    reducers: {
        addEventToHistory: (state, data) => state.find(tab => tab.id === data.payload.id).history.append({ ts: new Date().toISOString(), text: data.text }),
        removeDocument: (state, data) => state.filter(tab => (tab.id !== data.payload.id)),
        updateDocument: (state, data) => {
            const tab = state[data.payload.id]
            if (!("history" in tab))
                tab.history = []

            for (var prop in data.payload)
                if (tab[prop] !== data.payload[prop]) {
                    // tab.history.append({ ts: new Date().toISOString(), text: `updated ${translation[prop]} from ${tab[prop]} to ${data.payload[prop]}` })
                    tab[prop] = data.payload[prop]
                }
        },
        focus: (state, data) => {
            state[data.payload.id] = { ...data.payload }
        },
        focus_tab: (state, data) => {
            if (state.list.hasOwnProperty(data.payload))
                state.focused_tab = data.payload
        },
        open_tab: (state, data) => {
            const current_tab = state.focused_tab === "" ? null : state.list[state.focused_tab]
            // console.log("##################")
            // console.log("##################")
            // console.log("##################")
            // console.log("current tab:" + state.focused_tab)
            // console.log(JSON.stringify(current_tab?.data))
            // console.log(JSON.stringify(data.payload.data))
            // console.log("id:" + current_tab?.data?.id)

            if (!data.payload?.component && (data.payload?.data?.id === undefined || (data.payload?.data?.id === current_tab?.data?.id))) { // only data no component so update current tab
                return update_current_tab(state, data)
            }

            let tab_candidate = null
            // console.log("loop")
            for (const key in state.list) {
                const tab = state.list[key]
                // console.log(tab.component)
                // console.log(data.payload.component)
                // console.log("tab.component !== data.payload.component " + (tab.component !== data.payload.component))
                if ((data.payload?.component !== undefined && (tab.component !== data.payload.component)) || (data.payload?.component === undefined && (tab.component !== current_tab?.component)))
                    continue

                // console.log(data.payload.data)
                // console.log(tab.data)

                if (data.payload.data?.id === undefined) { // we are looking for an overview tab
                    // console.log("open overview")
                    // console.log(tab.data.id)
                    if (tab.data.id !== undefined) // details found :(
                        continue

                    // console.log("overview found")
                    state.focused_tab = key
                    return
                }

                if (data.payload.data?.id !== undefined) { // need to open a details view
                    // console.log("open detail")
                    if (tab.data.id === undefined) { // found one without a detail
                        tab_candidate = key
                        // update_tab(tab, data)
                        // state.focused_tab = key
                        continue
                    }

                    if (data.payload.data.id === tab.data.id) { // found the tab change focus
                        if (state.focused_tab !== key && current_tab?.data?.id === undefined)
                            delete state.list[state.focused_tab]

                        if (data.payload.data.workflow)
                            state.list[key].data.workflow = data.payload.data.workflow

                        state.focused_tab = key
                        return
                    }
                }
            }

            if (tab_candidate) {
                update_tab(state, state.list[tab_candidate], data)
                state.focused_tab = tab_candidate
                return
            }

            // current tab is set and we have an id; iff it's the same update
            if (current_tab && (data.payload.data?.id !== undefined) && (data.payload?.component === current_tab?.component) && (data.payload.data?.id === current_tab?.data.id))
                return update_current_tab(state, data)

            open_new_tab(state, data)
        },
        close_tab: (state, data) => {
            close_tab_impl(state, data)
        },
        close_current_tab: (state) => {
            close_tab_impl(state, { payload: { id: state.focused_tab } })
        }
    }
})

export const SelectAllTabs = createSelector((state) => state.tabs, (tabs) => tabs);
export const SelectFocusedTab = createSelector((state) => state.tabs, (tabs) => tabs.focused_tab);
export const { actions, reducer } = tabSlice
export const { focus_tab, open_tab, close_tab, close_current_tab } = actions
export function useOpenTab() {
    const dispatch = useDispatch()
    return (data) => {
        return (e) => {
            if (e)
                e.preventDefault()
            dispatch(open_tab(data))
        }
    }
}

export default function Layout() {
    const tabs = useSelector(SelectAllTabs)
    const focused_tab = useSelector(SelectFocusedTab)
    const dispatch = useDispatch()
    const tab = useOpenTab()
    return (
        <>
            <Row key="tabbar">
                <Tabs defaultActiveKey="profile" activeKey={focused_tab} onSelect={(k) => dispatch(focus_tab(k))} >
                    {
                        Object.entries(tabs.list).map(([key, value], i) => {
                            return (
                                <Tab eventKey={key} title={
                                    <>
                                        <span>
                                            {translate(value.title)}
                                            <BsXCircle onClick={(e) => { e.preventDefault(); dispatch(close_tab({ "id": key })) }} />
                                        </span>
                                    </>
                                } active={key === focused_tab} key={i} />
                            )
                        })
                    }
                </Tabs>
            </Row>
            <Row key="content" className="content">
                {
                    Object.entries(tabs.list).map(([key2, value]) => {
                        if (key2 === focused_tab) {
                            switch (value.component) {
                                case "Dashboard": return <Dashboard {...value.data} />
                                case "Customers": return <Customers {...value.data} />
                                case "Contracts": return <Contracts {...value.data} />
                                case "Corporations": return <Corporations {...value.data} />
                                case "Calendar": return <Calendar {...value.data} />
                                case "Documents": return <Documents {...value.data} />
                                case "Materialrisks": return <Materialrisks {...value.data} />
                                case "Settings": return <Settings {...value.data} />
                                case "Workflows": return <Workflows {...value.data} />
                                case "Employees": return <Employees {...value.data} />

                                default: return "Default"
                            }
                        }
                    })
                }
            </Row>
        </>
    )
}