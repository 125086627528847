import Row from 'react-bootstrap/Row';
import { TextInput } from '../../TextInput'

export default function Layout(props) {
    return (
        <>
            <Row>
                <TextInput name="street" col="2" {...props} />
                <TextInput name="house_number" col="1" {...props} />
            </Row>
            <Row>
                <TextInput name="postal_code" col="1" {...props} />
                <TextInput name="city" col="1" {...props} />
            </Row>
            <Row>
                <TextInput name="qm_living_space" col="1" {...props} />
            </Row>
            <Row>
                <TextInput name="qm_living_space_cellar" col="1" {...props} />
            </Row>
            <Row>
                <TextInput name="qm_cellar_space" col="1" {...props} />
            </Row>
        </>
    )
}